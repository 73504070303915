<template>
  <v-container>
    <v-row>
      <v-col cols="8">
        <v-text-field
          v-model="search"
          outlined
          label="Search Users"
          @input="fetchUsers"
          append-icon="mdi-magnify"
          class="mb-4"
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-btn color="primary" @click="createNewdialog = true"> Create </v-btn>
      </v-col>
      <v-col cols="2"
        ><v-btn color="primary" dark @click="handleEmailSelected">
          <v-icon>mdi-email</v-icon>
          Email clients
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="users"
      :loading="loading"
      :items-per-page="limit"
      class="elevation-1"
      :hide-default-footer="true"
      show-select
      :single-select="false"
    >
      <template v-slot:item.index="{ item }">
        {{ item.slNo }}
      </template>
      <template v-slot:item.createdAt="{ item }">
        <p>
          <span color="primary">{{ item.createdAt | getFormattedDate }}</span>
        </p>
      </template>
      <template v-slot:item.progress="{ item }">
        <v-select
          v-model="item.progress"
          item-value="value"
          item-text="text"
          class="mt-3"
          outlined
          :items="progressTypes"
          @change="updateUser(item)"
        ></v-select>
      </template>
      <template v-slot:item.lastName="{ item }">
        <p color="primary">{{ item.firstName }} {{ item.lastName }}</p>
      </template>
      <template v-slot:item.source="{ item }">
        <p color="primary">{{ item.source }} {{ item.leadDescription }}</p>
      </template>
      <template v-slot:item.email="{ item }">
        <a :href="generateMailtoLink(item)">{{ item.email }}</a>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn small @click="viewDetails(item)"> View details </v-btn>
      </template>
    </v-data-table>

    <v-pagination
      v-model="page"
      :length="pageCount"
      @input="fetchUsers"
      class="mt-4"
    ></v-pagination>
    <v-dialog v-model="createNewdialog" width="auto">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col>
              <v-dialog v-model="dialog3" width="800">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark v-bind="attrs" v-on="on">
                    Create New Client
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    <h1>Create New Client</h1>
                  </v-card-title>

                  <v-card-text>
                    <consultant-user-create />
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialog3 = false">
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog></v-col
            ><v-col>
              <v-btn
                dark
                color="secondary"
                @click="$router.push('/consultant/company/0/edit')"
                >Create new Company</v-btn
              >
            </v-col></v-row
          >
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="createNewdialog = false"
            >Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog3" width="800">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          <h1>Create New Client</h1>
        </v-card-title>

        <v-card-text>
          <consultant-user-create />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog3 = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import gql from "graphql-tag";
import {
  selectItem,
  emailSelected,
  formatDate,
  generateMailtoLink,
  formatDateWithTime,
} from "../../../methods/user";
import progressTypes from "../../../items/progressTyes.json";
import ConsultantUserCreate from "../../../components/Base/User/ConsultantUserCreate.vue";

export default {
  components: { ConsultantUserCreate },
  name: "ViewUsers",
  data() {
    return {
      users: [],
      loading: false,
      limit: 10,
      selectedUsers: [],
      createNewdialog: false,
      dialog3: false,
      page: 1,
      pageCount: 0,
      progressTypes: progressTypes,
      search: "",
      me: null,
      headers: [
        { text: "#", value: "slNo", sortable: false },
        { text: "Name", value: "lastName" },
        { text: "Email", value: "email" },
        { text: "Progress", value: "progress", width: "240px" },
        { text: "Source", value: "source" },
        { text: "Phone", value: "telNumber" },
        { text: "Date created", value: "createdAt" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  methods: {
    async fetchUsers() {
      this.loading = true;
      const USERS_QUERY = gql`
        query GetUsers($limit: Int, $page: Int, $query: JSON!) {
          users(limit: $limit, page: $page, query: $query) {
            records {
              id
              firstName
              lastName
              email
              source
              progress
              leadDescription
              telNumber
              createdAt
            }
            paginator {
              userCount
              perPage
              pageCount
              currentPage
              hasNextPage
              hasPrevPage
            }
          }
        }
      `;

      try {
        const variables = {
          limit: this.limit,
          page: this.page,
          query: this.search
            ? {
                consultant: this.me,
                role: "user",
                $or: [
                  { firstName: { $regex: this.search, $options: "i" } },
                  { lastName: { $regex: this.search, $options: "i" } },
                ],
              }
            : {
                consultant: this.me,
                role: "user",
              },
        };

        const response = await this.$apollo.query({
          query: USERS_QUERY,
          variables,
        });
        const { records, paginator } = response.data.users;

        this.users = records.map((user, index) => ({
          ...user,
          slNo: paginator.perPage * (paginator.currentPage - 1) + index + 1,
        }));

        this.pageCount = paginator.pageCount;
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        this.loading = false;
      }
    },
    async fetchMe() {
      const ME_QUERY = gql`
        query {
          me {
            id
          }
        }
      `;

      try {
        const response = await this.$apollo.query({ query: ME_QUERY });
        this.me = response.data.me.id;
      } catch (error) {
        console.error("Error fetching me:", error);
      }
    },

    async updateUser(item) {
      // console.log(item);
      try {
        const response = await this.$apollo.mutate({
          mutation: gql`
            mutation updateUser($user: UserUpdateInput!) {
              updateUser(user: $user) {
                id
                updated
              }
            }
          `,
          variables: {
            user: {
              id: item.id,
              progress: item.progress,
              consultant: item.consultant,
            },
          },
        });

        if (!response) {
          throw new Error("Something went wrong when updating the user!");
        }

        if (response.errors && response.errors.length > 0) {
          throw new Error(response.errors[0].message);
        }
        if (
          response.data &&
          response.data.updateUser &&
          response.data.updateUser.user
        ) {
          const updatedUser = response.data.updatedUser.user;
          item.progress = updatedUser.progress;
          // Update other relevant fields if needed
        }

        this.$swal("Success", "Data has been updated", "success");
        this.fetchUsers();
      } catch (error) {
        console.error(error);
      }
    },

    handleClick(item) {
      selectItem(this.selectedUsers, item);
    },

    handleEmailSelected() {
      emailSelected(this.selectedUsers);
      this.selectedUsers = [];
    },

    formatDate(date) {
      return formatDate(date);
    },
    formatDateWithTime(date) {
      return formatDateWithTime(date);
    },

    generateMailtoLink(item) {
      return generateMailtoLink(item);
    },
    viewDetails(item) {
      this.$router.push(`/consultant/user/${item.id}`);
    },
  },
  watch: {
    search() {
      this.page = 1;
      this.fetchUsers();
    },
  },
  mounted() {
    this.fetchMe().then(() => {
      this.fetchUsers();
    });
  },
};
</script>

<style scoped>
/* Add any custom styles here */
</style>
